<template>
  <div class="Technologies-root spacer-80">
    <div class="container">
      <h3 class="section-heading text-center">Technologies</h3>
      <div class="technologies-card-container card-container">
        <div class="card vm-bg-light" v-for="(tech, idt) in technology" :key="idt">
          <div class="card-body">
            <h5 class="card-title" v-html="tech.title"></h5>
            <p class="card-text">{{tech.content}}</p>
          </div>
          <div class="card-icons">
            <img :src="tech.fullImage" :alt="tech.title" />
            <img :src="tech.dynamic_featured_image" :alt="tech.title" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      technology: []
    };
  },
  mounted() {
    this.loader = this.showSpinner();
    axios
      .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
        params: {
          post_type: "technologies"
        }
      })
      .then(response => {
        this.loader.hide();
        this.technology = response.data.customPostList;
      })
      .catch(error => {
        console.log("error", error);
      });
  }
};
</script>