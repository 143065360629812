<template>
  <div class="home-potfolio-parent">
    <div class="portfolio-items-container">
      <div
        class="portfolio-item yellow-play-btn"
        v-for="(PortfolioItem, idp) in SinglePortfolios"
        :key="idp"
      >
        <Portfolio v-bind:PortfolioItem="PortfolioItem" />
      </div>
    </div>
  </div>
</template> 
<script>
import Portfolio from "../Common/Portfolio.vue";

export default {
  components: {
    Portfolio
  },
  props: ["SinglePortfolios"]
};
</script>