<template>
  <div class="Process-root spacer-80 text-center bg-dark">
    <div class="container">
      <h3 class="section-heading">Our Process</h3>
      <div class="process-container">
        <div class="process-box" v-for="(process, idp) in ourProcess" :key="idp">
          <div class="process-icon-container">
            <div class="process-icon">
              <img :src="process.fullImage" :alt="process.title" />
            </div>
          </div>
        </div>
      </div>
      <div class="process-timeline">
        <p v-for="(process, idp) in ourProcess" :key="idp">
          <span>{{process.title}}</span>
          <span>
            <img src="../../assets/images/Icons/arrowsign.svg" alt />
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      ourProcess: []
    };
  },
  mounted() {
    this.loader = this.showSpinner();
    axios
      .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
        params: {
          post_type: "our_process"
        }
      })
      .then(response => {
        this.loader.hide();
        this.ourProcess = response.data.customPostList;
      })
      .catch(error => {
        console.log("error", error);
      });
  }
};
</script>
