<template>
  <div class="about">
    <FixedNavbar />
    <div class="su_page_content">
      <TitleBanner v-bind:PageTitle="BannerTitle" />
      <AboutText v-bind:textBlock="textBlock" />
      <Process />
      <Technologies />
      <AboutPortfolio v-bind:SinglePortfolios="AboutPortfolios" />
      <GetInTouchForm />
      <Resources v-bind:ResourceType="ResourceType" />
      <QuoteCta v-if="widgetCustomHTML.length>0" v-bind:CustomWidget="widgetCustomHTML" />
    </div>
    <Footer />
  </div>
</template> 

<script>
import FixedNavbar from "@/components/Common/Navbars/FixedNavbar.vue";
import TitleBanner from "@/components/Common/TitleBanner.vue";
import AboutText from "@/components/About/AboutText.vue";
import Process from "@/components/About/Process.vue";
import AboutPortfolio from "@/components/About/AboutPortfolio.vue";
import Technologies from "@/components/About/Technologies.vue";
import GetInTouchForm from "@/components/About/GetInTouchForm.vue";
import Resources from "@/components/Common/Resources/Resources.vue";
import QuoteCta from "@/components/Common/QuoteCta.vue";
import Footer from "@/components/Common/Footer.vue";
import axios from "axios";
export default {
  name: "About",
  components: {
    FixedNavbar,
    TitleBanner,
    AboutText,
    Process,
    AboutPortfolio,
    Technologies,
    GetInTouchForm,
    Resources,
    QuoteCta,
    Footer
  },
  data() {
    return {
      ResourceType: "caseStudy",
      AboutPortfolios: [],
      BannerTitle: [],
      widgetCustomHTML: [],
      textBlock: []
    };
  },
  mounted() {
    this.getPortfolio();
    this.getBanner();
    this.getQuote();
    this.getTextBlock();
    this.setMetaInfo(400)
  },
  methods: {
    getPortfolio() {
      this.loader1 = this.showSpinner();
      axios
        .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
          params: {
            post_type: "portfolio",
            taxonomy: "portfolio_categories",
            custom_link_field: "video_link",
            featured_portfolio: "show_portfolio_on_home_page"
          }
        })
        .then(response => {
          this.loader1.hide();
          this.Portfolios = response.data.customPostList;
          for (let i = 0; i < this.Portfolios.length; i++) {
            if (this.Portfolios[i].show_on_home_page) {
              this.AboutPortfolios.push(this.Portfolios[i]);
            }
          }
          console.log("Portfolios==", this.Portfolios);
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    getBanner() {
      this.loader2 = this.showSpinner();
      axios
        .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
          params: {
            post_type: "header_banner",
            taxonomy: "banner_type",
            cat_name: "about-page-banner"
            // custom_link_field: "cta_button"
          }
        })
        .then(response => {
          this.loader2.hide();
          // this.BannerContent = response.data.customPostList;
          this.BannerTitle = response.data.customPostList;
          console.log("BannerContent==", this.BannerContent);
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    getQuote() {
      this.loader = this.showSpinner();
      axios
        .get(`${process.env.VUE_APP_API}/wp/v1/custom_widget`, {
          params: {
            type: "widget_custom_html"
          }
        })
        .then(response => {
          this.loader.hide();
          this.widgetCustomHTML = response.data.customWidgetList;
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    getTextBlock() {
      this.loader3 = this.showSpinner();
      axios
        .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
          params: {
            post_type: "custom_text_blocks",
            taxonomy: "text_block_cat",
            cat_name: "about-video-designs"
          }
        })
        .then(response => {
          this.loader3.hide();
          this.textBlock = response.data.customPostList;
        })
        .catch(error => {
          console.log("error", error);
        });
    }
  }
};
</script>
