<template>
  <div class="about-text text-center">
    <div class="container">
      <div v-for="(aboutText, idp) in textBlock" :key="idp">
        <h3 class="section-heading mb-3">{{ aboutText.title }}</h3>
        <p>{{ aboutText.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["textBlock"]
};
</script>