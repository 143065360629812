<template>
  <div class="getInTouchFormWrapper spacer-80">
    <div class="container">
      <h3 class="section-heading text-center">Get in touch</h3>
      <div class="gzvid-mkto-form">
        <form v-if="!isFormSubmitted" id="mktoForm_5844"></form>
        <div v-if="isFormSubmitted" class="form_subscribe_thanks color-white">
          <unicon name="uniCheckCircle" fill="deeppink" />Thank you for submitting your request.
          <span
            class="d-block"
          >Our experts will contact you soon.</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { invalidDomains } from "./../../variables/commonVar";
export default {
  data() {
    return {
      isFormSubmitted: false
    };
  },
  methods: {},
  mounted() {
    window.MktoForms2.loadForm(
      "//app-lon03.marketo.com",
      "690-NGB-767",
      5844,
      form => {
        form.onSuccess(() => {
          this.isFormSubmitted = true;
          return false;
        });
      }
    );
    this.marketoReady(invalidDomains);
  }
};
</script>